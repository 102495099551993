import './StateCard.scss';
import React from 'react';

export const InitialState = () => {
  return (
    <div className="state-box">
      <img src="/assets/img/initial_state.svg" alt="Initial State" />
    </div>
  );
};

export const LoadingState = () => {
  return (
    <div className="state-box">
      <img src="/assets/img/loading_state.svg" alt="Loading State" />
      <h1>Looking for the GIFt...</h1>
    </div>
  );
};