export const LIMIT_SEARCH_RESULT = 10;

export const FILTER_OPTION_DEFAULT = 'random';
export const FILTER_OPTION = {
  random: '/v1/gifs/search',
  trending: '/v1/gifs/trending'
};

export const DEFAULT_PAGE = 1;
export const LIMIT_PAGE_NUMBER = 5;

export const API_KEY = 'B3isdrLaC3Bj4Ob3Q4GPEkbyLo9o2meN';