import React from 'react';
import ReactDOM from 'react-dom';
import App from './pages/app/App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <App />,
  document.querySelector('#root')
);

serviceWorker.unregister();