import './FilterBar.scss';
import React, { Component } from 'react';
import {
  FILTER_OPTION_DEFAULT,
  DEFAULT_PAGE
} from '../../utils/constants/app.constant';

class FilterBar extends Component {
  state = {
    filter: FILTER_OPTION_DEFAULT
  }

  onFilterSelect(filter) {
    let {term, onFilterSelect} = this.props;
    
    onFilterSelect(filter, DEFAULT_PAGE, term);
    this.setState({filter: filter});
  }

  render() {
    let {filter} = this.state;

    return (
      <div className="filter-control">
        <button onClick={() => this.onFilterSelect('random')} className={`${filter === 'random' ? 'filter-control__button-filter--selected' : ''} filter-control__button-filter u-uppercase-text`}>random</button>
        <button onClick={() => this.onFilterSelect('trending')} className={`${filter === 'trending' ? 'filter-control__button-filter--selected' : ''} filter-control__button-filter u-uppercase-text`}>trending</button>
      </div>
    );
  }
}

export default FilterBar;