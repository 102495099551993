import './App.scss';
import React, { Component } from 'react';
import giphyAPI from '../../api/giphy';
import SearchBar from '../../components/SearchBar/SearchBar';
import FilterBar from '../../components/FilterBar/FilterBar';
import ImageList from '../../components/ImageList/ImageList';
import PaginationBar from '../../components/PaginationBar/PaginationBar';
import { 
  FILTER_OPTION_DEFAULT,
  FILTER_OPTION,
  LIMIT_SEARCH_RESULT,
  API_KEY,
} from '../../utils/constants/app.constant';
import {InitialState, LoadingState} from '../../components/StateCard/StateCard';

class App extends Component {
  state = {
    term: '',
    images: null,
    pagination: {},
  }

  onSearchSubmit = async (filter=FILTER_OPTION_DEFAULT, page, term=this.state.term) => {
    this.setState({images: []})

    if (term !== '') {
      let offset = (Number(page) - 1) * LIMIT_SEARCH_RESULT;

      const response = await giphyAPI.get(FILTER_OPTION[filter], {
        params: {api_key: API_KEY, q: term, limit: LIMIT_SEARCH_RESULT, offset: offset}
      });

      this.setState({
        term: term,
        images: response.data.data,
        pagination: response.data.pagination
      }); 
    }
    else {
      this.setState({images: null})
    }
  }

  render() {
    let {term, images, pagination} = this.state;

    return (
      <>
        <header className="header">
          <div className="header__text-box">
            <h1>
              <span className="header__text-box__title--main">GIF</span>
              <span className="header__text-box__title--sub">t'ed</span>
            </h1>
          </div>
        </header>
        <main>
          <section className="section-search">
            <SearchBar onSubmit={this.onSearchSubmit} />
            <FilterBar onFilterSelect={this.onSearchSubmit} term={term}/>
          </section>
          <section className="section-image">
            {
              images === null ?
              <InitialState />
              :
              images.length === 0 ?
              <LoadingState />
              :
              <>
                <ImageList images={images} />
                <PaginationBar onPageSelect={this.onSearchSubmit} pagination={pagination} />
              </>
            }
          </section>
        </main>
      </>
    );
  }
}

export default App;