import './ImageCard.scss';
import React, { Component } from 'react';
import GifPlayer from 'react-gif-player';

class ImageCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      spans: 0,
      playing: ''
    }

    this.imageRef = React.createRef();
  }

  componentDidMount() {
    this.setSpans();
  }

  setSpans = () => {
    const height = this.imageRef.current.props.height;
    const spans = Math.ceil(height / 10) + 1; // add 1 extra row space
    this.setState({spans});
  }

  setControl(playing) {
    this.setState({
      playing: playing ? 'playing' : ''
    });
  }

  render() {
    const {spans, playing} = this.state;
    const {image} = this.props;
    const {images, title} = image;

    return (
      <div style={{gridRowEnd: `span ${spans}`}}>
        <GifPlayer 
          gif={images.downsized.url}
          still={images.downsized_still.url}
          alt={title}
          ref={this.imageRef}
          height={images.downsized.height}
          onTogglePlay={playing => this.setControl(playing)}
          className={`gif-image ${playing}`}
         />
      </div>
    );
  }
}

export default ImageCard;