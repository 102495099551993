import './SearchBar.scss';
import React, { Component } from 'react';
import {
  FILTER_OPTION_DEFAULT,
  DEFAULT_PAGE
} from '../../utils/constants/app.constant';

class SearchBar extends Component {
  state = {
    term: ''
  }

  onFormSubmit(e) {
    e.preventDefault();

    this.props.onSubmit(FILTER_OPTION_DEFAULT, DEFAULT_PAGE, this.state.term);
  }

  render() {
    return (
      <div className="form-control">
        <form className="form-control__form" onSubmit={e => this.onFormSubmit(e)}>
          <input
            type="text" 
            className="form-control__form__input u-center-text"
            value={this.state.value}
            onChange={e => {
              this.setState({term: e.target.value})
            }}
            autoFocus={true}
          />
        </form>
        <span className="form-control__hint">Press <code>Enter</code> to search</span>
      </div>
    );
  }
}

export default SearchBar;