import './PaginationBar.scss';
import React, { Component } from 'react';
import {
  FILTER_OPTION_DEFAULT,
  DEFAULT_PAGE,
  LIMIT_PAGE_NUMBER
} from '../../utils/constants/app.constant';

class PaginationBar extends Component {
  state = {
    currentPage: DEFAULT_PAGE
  }

  onPageSelect(e) {
    let {currentPage} = this.state;
    let {count, total_count} = this.props.pagination;
    let {page, control} = e.target.dataset;
    let gotoPage = 1;

    switch (control) {
      case 'prevPage':
        gotoPage = Number(currentPage) - 1;
        break;
      case 'nextPage':
        gotoPage = Number(currentPage) + 1;
        break;
    
      default:
        gotoPage = page;
        break;
    }

    let maxPage = Math.ceil(total_count / count);
    if (gotoPage < 1) {
      gotoPage = 1;
    }
    else if (gotoPage > maxPage) {
      gotoPage = maxPage
    }

    this.props.onPageSelect(FILTER_OPTION_DEFAULT, gotoPage);
    this.setState({currentPage: gotoPage});
  }

  renderPageNumbers(currentPage) {
    let pageOffset = Math.floor((Number(currentPage) - 1) / LIMIT_PAGE_NUMBER);
    let firstNumber = (pageOffset * LIMIT_PAGE_NUMBER);
    let lastNumber = firstNumber + LIMIT_PAGE_NUMBER;
    
    let pageNumbers = [];
    for (let i = firstNumber; i < lastNumber; i++) {
      pageNumbers.push(i + 1);
    }

    return pageNumbers.map(number => {
      let classes = currentPage === number ? 'active' : '';

      return <li key={number} onClick={e => this.onPageSelect(e)} data-page={number} className={`pagination__number ${classes}`} >{number}</li>
    })
  }

  render() {
    let {currentPage} = this.state;
    let {count, total_count} = this.props.pagination;
    let maxPage = Math.ceil(total_count / count);

    return (
      <div className="pagination">
        <ul>
          <li className="pagination__number" onClick={e => this.onPageSelect(e)} data-control='prevPage' disabled={currentPage === 1 ? true : false}>&laquo;</li>
          {this.renderPageNumbers(currentPage)}
          <li className="pagination__number" onClick={e => this.onPageSelect(e)} data-control='nextPage' disabled={currentPage === maxPage ? true : false}>&raquo;</li>
        </ul>
      </div>
    );
  }
}

export default PaginationBar;